import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";

import { Link } from "react-router-dom";
import FixedButtonsFooter from "../components/FixedButtonsFooter/FixedButtonsFooter";

import iaTelecoLogo from "assets/img/ia-teleco.png";

const ResponsiveIframe = () => {
  return (
    <div className="ratio ratio-16x9">
      <iframe
        src="https://www.youtube.com/embed/lJBxBPogOPg?si=rP4Fly9tdtrw3gHO"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  );
};

const Dashboard = () => {
  return (
    <div className="content ml-2 mr-2">
      <Row>
        <Col xs="12">
          <Card>
            <CardHeader></CardHeader>
            <CardBody className="all-icons">
              <div className="d-flex justify-content-center align-items-center flex-column">
                <img src={iaTelecoLogo} width={100} alt="Logo Teleco"></img>

                <p className="mt-4 text-center">
                  A ferramenta que já mudou os resultados de mais de 5.000 alunos que operam em cassinos online.
                </p>

                <p className="text-center">
                  Quaisquer dúvidas não deixe de entrar em contato no suporte.
                </p>

                <h4 className="mt-4">Links uteis abaixo:</h4>

                <a
                  href="https://youtu.be/lJBxBPogOPg?si=LBxzxI4rNgZ9iYq4"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="btn btn-secondary"
                >
                  Aula - I.A do Teleco
                </a>
                <a
                  href="https://youtu.be/XH3oaAdWy8o?si=JQhECPAHFoiP7dnp"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="btn btn-secondary"
                >
                  Aula - Como pegar sinais por horário
                </a>

                <a
                  href="https://pay.kirvano.com/5fdb00e0-5206-4bf1-9b53-aaadb5608357"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="btn btn-secondary"
                >
                  Acesso Vitalicio I.A. Teleco
                </a>
                <a
                  href="https://go.aff.br4-partners.com/mldhslr5?utm_campaign=ia-teleco"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="btn btn-secondary"
                >
                  Cadastre-se aqui na BR4BET
                </a>
                <a
                  href="https://t.me/telecosfree"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="btn btn-secondary"
                >
                  Grupo Free do Teleco - Lives
                </a>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <FixedButtonsFooter />
      </Row>
    </div>
  );
};

export default Dashboard;
