import React, { useState, useEffect } from "react";

const AnimatedMessage = () => {
  const [mensagens, setMensagens] = useState([]);

  useEffect(() => {
    const atualizarMensagem = () => {
      const novaMensagem = gerarMensagem();
      setMensagens((prev) => [...prev, novaMensagem]);

      // Remover mensagens antigas após 7 segundos
      setTimeout(() => {
        setMensagens((prev) => prev.slice(1));
      }, 7000);
    };

    atualizarMensagem(); // Atualizar imediatamente
    const interval = setInterval(atualizarMensagem, 20000); // Atualizar a cada 6 segundos

    return () => clearInterval(interval);
  }, []);

  const gerarMensagem = () => {
    const nomes = [
        "João Augusto",
        "Maria Clara",
        "Lucas Silva",
        "Fernanda Costa",
        "Rafael Pereira",
        "Ana Luiza",
        "Carlos Eduardo",
        "Beatriz Almeida",
        "Rodrigo Santos",
        "Juliana Oliveira",
        "Pedro Henrique",
        "Gabriela Martins",
        "Ricardo Alves",
        "Sofia Lima",
        "Vinícius Carvalho",
        "Larissa Monteiro",
        "André Batista",
        "Camila Ferreira",
        "Renato Almeida",
        "Patrícia Duarte",
        "Daniel Rocha",
        "Bianca Cardoso",
        "Eduardo Fonseca",
        "Isabela Ramos",
        "Felipe Mendes",
        "Aline Souza",
        "Thiago Oliveira",
        "Mariana Ribeiro",
        "César Tavares",
        "Luana Castro",
        "Gustavo Nunes",
        "Carla Machado",
        "Leonardo Barros",
        "Letícia Moreira",
        "Paulo Vieira",
        "Vanessa Correia",
        "Roberto Farias",
        "Tatiane Pires",
        "Diego Antunes",
      ];
    const nome = nomes[Math.floor(Math.random() * nomes.length)];
    const lucro = (Math.random() * 100 + 10).toFixed(2);
    return `${nome} lucrou R$${lucro}`;
  };

  return (
    <div style={{ position: "relative", height: "40x", overflow: "hidden" }}>
      {mensagens.map((mensagem, index) => (
        <div key={index} className="animated-message">
          {mensagem}
        </div>
      ))}
    </div>
  );
};

export default AnimatedMessage;
