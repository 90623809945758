import React, { useState } from "react";
import axios from "axios";
import { Button, Row, Col } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";

const SignalsGenerator = () => {
  const dateNow = moment(new Date()).format("YYYY-MM-DD");
  const [generatedList, setGeneratedList] = useState("");
  const [loading, setLoading] = useState(false);

  const generateSignals = async () => {
    setLoading(true);
    try {
      // Pegar o horário atual e o anterior
      const currentHour = moment().subtract(1, "hour"); // Horário anterior
      const formattedDate = currentHour.format("YYYY-MM-DD");

      // Parâmetros da requisição
      const params = {
        date: formattedDate,
        numberVelas: 100,
        betHouse: "MINES_BET",
      };

      // Consumir a API
      const response = await axios.get(
        "https://api-aviator-cb5db3cad4c0.herokuapp.com/history-odd",
        { params }
      );

      const data = response.data;

      // Filtrar velas maiores que 10
      const filteredVelas = data.filter((item) => parseFloat(item.odd) > 10);

      // Obter os últimos 8 sinais e inverter a ordem
      const signalsList = filteredVelas
        .slice(-8)
        .reverse()
        .map((vela) => {
          // Adicionar 1 hora ao horário da vela
          const [hora, minuto, segundo] = vela.hour.split(":").map(Number);
          const novaHora = (hora + 1) % 24; // Ajusta para o ciclo de 24 horas
          const novoHorario = `${novaHora.toString().padStart(2, "0")}:${minuto
            .toString()
            .padStart(2, "0")}:${segundo.toString().padStart(2, "0")}`;
          return `${novoHorario} - 3 a 5X`;
        });

      // Gerar a lista formatada
      const formattedList = `
LISTA DE SINAIS:

${signalsList.join("\n")}

Recomendação:

⏰ Entrar 15 segundos antes de cada sinal!
🛡 Proteção ativa no 1.50X.
🌹 Todos os sinais podem puxar rosa, se você é conservador, garante 3 a 5X no bolso.
🏹 Fazer até 3 tentativas mediante o histórico do gráfico.

Bateu a meta? VAZAAAAAA!
      `;

      setGeneratedList(formattedList);
    } catch (error) {
      console.error("Erro ao gerar sinais:", error);
      setGeneratedList("Erro ao gerar sinais. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="content d-flex flex-column align-items-center mt-4 pt-4">
      <Row className="d-flex justify-content-center align-items-center w-100">
        <Col xs={3}>
          <Link to="signals">
            <div className="d-flex justify-content-center align-items-center">
              <i aria-hidden="true" className="fas fa-arrow-left fs-xxl"></i>
              <span className="ml-2">voltar</span>
            </div>
          </Link>
        </Col>
        <Col xs={9}>
          <h3 className="title m-0 text-right">I.A. PRO</h3>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center align-items-center mb-3">
        <Col xs="auto">
          <Button color="primary" onClick={generateSignals} disabled={loading}>
            {loading ? "Gerando Sinais..." : "Gerar Sinais"}
          </Button>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center align-items-center mt-3">
        <Col xs={12} md={8}>
          <pre
            style={{
              backgroundColor: "#f4f4f4",
              padding: "10px",
              color: "black",
              borderRadius: "5px",
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              textAlign: "left",
            }}
          >
            {generatedList || "Clique em 'Gerar Sinais' para obter a lista."}
          </pre>
        </Col>
      </Row>
    </div>
  );
};

export default SignalsGenerator;
