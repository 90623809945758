import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  Input,
  Label,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import FixedButtonsFooter from "../components/FixedButtonsFooter/FixedButtonsFooter";
import { Enviroments } from "enviroments/enviroments";

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);

  useEffect(() => {
    const userId = localStorage.getItem("userId");

    const fetchUsers = async (page) => {
      try {
        const headers = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
          },
        };
        const response = await axios.get(
          `${Enviroments.API_URL}admin/users?userId=${userId}&page=${page}&limit=${usersPerPage}`,
          headers
        );
        setUsers(response.data.users);
        setFilteredUsers(response.data.users);
        setTotalUsers(response.data.total);
      } catch (error) {
        console.error("Erro ao buscar usuários:", error);
      }
    };

    fetchUsers(currentPage);
  }, [currentPage, usersPerPage]);

  // Função para mudar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Cálculo do número total de páginas
  const totalPages = Math.ceil(totalUsers / usersPerPage);

  // Filtrar usuários por nome, email ou data de criação
  useEffect(() => {
    let filtered = users;

    if (searchTerm) {
      filtered = filtered.filter(
        (user) =>
          user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (filterDate) {
      filtered = filtered.filter((user) => {
        const userCreatedDate = new Date(user.created_at).toLocaleDateString("en-CA"); // Formato YYYY-MM-DD
        return userCreatedDate === filterDate;
      });
    }

    setFilteredUsers(filtered);
  }, [searchTerm, filterDate, users]);

  // Ações de Editar ou Desativar
  const handleEditUser = (userId) => {
    Swal.fire("Função de editar ainda não implementada.");
  };

  const handleDeactivateUser = (userId) => {
    Swal.fire({
      title: "Tem certeza?",
      text: "Você deseja desativar este usuário?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, desativar!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Chamar API para desativar o usuário
        Swal.fire("Desativado!", "O usuário foi desativado.", "success");
      }
    });
  };

  return (
    <div className="content ml-2 mr-2">
      <Row>
        <Col xs="12">
          <Card>
            <CardHeader>
              <h4>Administração de Usuários</h4>
              <Row>
                <Col md="6">
                  <Input
                    type="text"
                    placeholder="Filtrar por nome ou email"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ maxWidth: "300px", marginTop: "10px" }}
                  />
                </Col>
                <Col md="6">
                  <Label for="filterDate">Filtrar por data de criação:</Label>
                  <Input
                    type="date"
                    id="filterDate"
                    value={filterDate}
                    onChange={(e) => setFilterDate(e.target.value)}
                    style={{ maxWidth: "200px", marginTop: "10px" }}
                  />
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>Saldo Inicial</th>
                    <th>Telefone</th>
                    <th>CPF</th>
                    <th>Role</th>
                    <th>Ativo</th>
                    <th>Criado em</th>
                    <th>Atualizado em</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.length > 0 ? (
                    filteredUsers.map((user) => (
                      <tr key={user.id}>
                        <td>{user.id}</td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>R$ {user.firstBalance?.toFixed(2)}</td>
                        <td>{user.phone}</td>
                        <td>{user.cpf}</td>
                        <td>{user.role}</td>
                        <td>{user.active ? "Sim" : "Não"}</td>
                        <td>{new Date(user.created_at).toLocaleDateString()}</td>
                        <td>{new Date(user.updated_at).toLocaleDateString()}</td>
                        <td>
                          <Button
                            color="warning"
                            size="sm"
                            onClick={() => handleEditUser(user.id)}
                            style={{ marginRight: "5px" }}
                          >
                            Editar
                          </Button>
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => handleDeactivateUser(user.id)}
                          >
                            Desativar
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="11" className="text-center">
                        Nenhum usuário encontrado
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {/* Paginação */}
              <Pagination aria-label="Page navigation" style={{flexWrap: 'wrap'}}>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    previous
                    onClick={() => paginate(currentPage - 1)}
                  />
                </PaginationItem>
                {[...Array(totalPages)].map((_, index) => (
                  <PaginationItem
                    key={index + 1}
                    active={currentPage === index + 1}
                  >
                    <PaginationLink onClick={() => paginate(index + 1)}>
                      {index + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink
                    next
                    onClick={() => paginate(currentPage + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <FixedButtonsFooter />
      </Row>
    </div>
  );
};

export default Admin;
